#center{
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.title {
    margin-left: 5rem;
}

.backdrop{
    z-index: 9999 !important;
    position: absolute;
}

#NewRunSpan {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

#Grid {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.page-footer {
    position: fixed;
    left: 5rem;
    bottom: 2vh;
    color: #ff9900;
    text-align: center;
}
