#center{
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.hiddenIcon{
    visibility: hidden;
}

.title {
    margin-left: 5rem;
}

.successTitle {
    margin-left: 5rem;
    color: #4caf50;
}

.o365-footer {
    position: fixed;
    left: 5rem;
    bottom: 2vh;
    color: #ff9900;
    text-align: center;
}

.dialogContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
