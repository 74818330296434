#center{
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.sampleLabel{
    display: flex;
    flex-direction: row;
    border: 2px solid grey;
    border-radius:2%;
    width: 17rem;
    overflow: visible;
    white-space: nowrap;
    text-align: left;
}

.data-grid-container .data-grid .cell > input {
    height: 15pt !important;
}

.hiddenIcon{
    visibility: hidden;
}

#printGrid {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#datasheet {
    flex-grow: 1;
    overflow: auto;
}

#buttonGrid {
    display: flex;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
    width:40rem;
}
#dataSheetGrid {
    margin-left: auto;
    margin-right: auto;
}

.dataGridContainer {
    margin-left: auto;
    margin-right: auto;
}

.headerCell {
    font-weight: bold;
}

.cell {
    padding: 5px !important;
}

.title {
    margin-left: 5rem;
}

.print-footer {
    position: fixed;
    left: 5rem;
    bottom: 2vh;
    color: #ff9900;
    text-align: center;
}

.dialogContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
