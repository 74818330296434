.App {
  text-align: center;
}

#menuButton {
  position : fixed;
  top: 1px;
  left: 1px;
  z-index: 10;
}

.footer {
  position: fixed;
  left: 5rem;
  bottom: 2vh;
  color: #ff9900;
  text-align: center;
}
.footer-a {
  cursor:pointer;
  width: 100%;
}
